<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                        <mbs-page-tab 
                            :items="MY_LINKS"/> 
                        <mbs-item-manager
                            :top_label="false"
                            :name_items="'Archived '+NAME_ITEMS"
                            :name_item="'Archived '+NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM" 
                            :header_items="HEADER_ITEMS" 
                            :data_items="ArchivedItems"   
                            :path_item="PATH_ITEM"   

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    let PAGE_NAME = "archive_"+DATA.ITEMS.RECEIVABLES.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.RECEIVABLES.names,
                NAME_ITEM:DATA.ITEMS.RECEIVABLES.name,
                VALUE_ITEMS:DATA.ITEMS.RECEIVABLES.values,
                VALUE_ITEM:DATA.ITEMS.RECEIVABLES.value,   

                table_actions:[ 
                    {type:"btn",action:"manage_receivable_accounts",color:"secondary", outlined:false,  text:"Manage Receivable Accounts"},  
                ] 
            }
        },
        created() {
            try {
                // this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 
            us(){
                return this.$store.getters.getUser
            },
            ud(){
                return this.$store.getters.getUserDetails
            },
            loading(){  
                return this.MBS.actions.loading
            },
            processes(){  
                return this.MBS.actions.processes
            }, 
            responses(){  
                return this.MBS.actions.responses
            },  
            /////////  
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("archive",2,true) 
                return thisLinks
            }, 
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.receivables,true) 
                return path
            }, 
            HEADER_ITEMS(){
                return [ 
                     {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"code",align:"center",show:true},     
                    {id:0,name:"Name",value:"name",show:true},       
                    {id:0,name:"description",value:"description",show:true},         
                    {id:0,name:"Amount (MWK)",value:"total_sold_price_",align:"right",show:true},       
                    {id:0,name:"created Date",value:'created_at_',show:true},     
                    {id:0,name:"updated Date",value:'updated_at_',show:false},    
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"Unarchive "+this.NAME_ITEM,action:"unarchive_item",icon:"delete_forever"}   
                    ]}, 
                ]
            }, 
            DATA_ITEMS(){  
                let items = this.$store.getters.getReceivables
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("created_at_server",false)) 
                return list
            },
            ArchivedItems(){
                let orders = this.DATA_ITEMS
                if(!orders){return null}
                let filtered_items = orders.filter(order=>{
                    return order.archive == true
                })
                return filtered_items
            } 
        },
        methods: { 
            TABLE_ACTION(action,item){
                try { 
                    let link = "/office/accounts/receivable"
                    const path = this.MBS.actions.COMPANY_LINK(link,true) 
                    console.log(path,'...');
                    if (action == "manage_receivable_accounts") {
                        this.MBS.actions.go(path)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
              
             
    
        },
        watch: {
             
        },
    }
</script>
 
